import tw from 'tailwind-styled-components';

interface IArticleStyleProps {
  $bgColor?: string;
}

export const ArticleCardsWrapper = tw.div<IArticleStyleProps>`
    bg-grey-100 
    px-5 
    py-10 
    md:px-[60px] 
    md:py-[120px] 
    ${(p) => (p.$bgColor ? p.$bgColor : 'bg-grey-100')}
`;

export const ArticleCardsTitle = tw.div`
    md:pb-[64px] 
    pb-8
`;

export const ArticleCardsDescription = `
    pt-3 
    lg:pt-4 
    lg:max-w-[432px] 
    !text-grey-400
`;

export const ShowMoreButton = tw.div`
    pt-8
    md:pt-[64px]
    flex 
    flex-col 
    md:flex-row 
    md:pb-0 
    items-center 
    lg:space-x-6 
    justify-center   
`;
