import { useEffect } from 'react';
import { useInfiniteHits, useInstantSearch, usePagination } from 'react-instantsearch';
import { DispensaryCards } from 'components/organisms/listings/dispensary-cards';
import { getSearchDispensariesData } from 'helpers/utils/get-search-category-data';
import { useAppDispatch } from 'redux/hooks';
import { updateSearch } from 'redux/reducers/search';

type Props = {
  category: string | string[];
  query: string | string[];
  tasticPlacementNumber: number;
  host?: string;
  isTablet?: boolean;
};

const DispensaryHits = ({ category, query, host, tasticPlacementNumber }: Props) => {
  const { hits, results } = useInfiniteHits();
  const { status } = useInstantSearch();
  const page = usePagination();
  const dispatch = useAppDispatch();
  const propsCard: any = getSearchDispensariesData(hits, results, page, category, query, tasticPlacementNumber);

  useEffect(() => {
    dispatch(
      updateSearch({
        dispensariesData: page?.nbHits,
        isDispensariesLoading: status === 'loading' || status === 'stalled' || false,
      }),
    );
  }, [dispatch, page?.nbHits, status]);
  return <DispensaryCards {...propsCard} host={host} />;
};

export default DispensaryHits;
