import Router from 'next/router';
import { cardListClickedTrack } from 'helpers/analytics/card-list-clicked-track';
import { getDispensaryAddressAlgolia } from './convert-to-dispensary-page-type';
const handleSegmentEvent = (data) => {
  cardListClickedTrack(data);
};

export const getSearchDispensariesData = (
  hits: any,
  results: any,
  page: any,
  category: any,
  query: any,
  tasticPlacementNumber: number,
) => {
  const remainingCards = page?.nbHits - hits?.length;
  const dispensaryData: any =
    hits &&
    hits?.map((item) => ({
      ...item,
      title: item?.name || '',
      timing: item?.hours || [],
      phoneNumber: item?.phone_number || '',
      mobile_image: item?.mobile_image,
      image: item.image,
      address: {
        text: getDispensaryAddressAlgolia(item?.address) || '',
      },
    }));
  const buttonDispensariesText = category
    ? remainingCards > results?.hitsPerPage
      ? `Show more (${results?.hitsPerPage})`
      : `Show more (${remainingCards})`
    : 'See all dispensaries results';
  const cardListData = {
    card_list_name: 'Dispensaries List',
    card_list_title: 'Dispensaries',
    card_list_type: '',
    card_list_facet_name: '',
    card_list_facet_value: '',
    card_list_url: '',
    link_name: buttonDispensariesText,
    placement: tasticPlacementNumber + 1 || 1,
    link_type: 'hyperlink',
  };
  const handleOnClick = () => {
    handleSegmentEvent(cardListData);
    if (category) {
      page.refine(page.currentRefinement + 1);
    } else {
      Router.push(`/search?query=${encodeURIComponent(query)}&category=dispensaries`);
    }
  };
  return {
    title: 'Dispensaries',
    current: hits?.length,
    max: results?.nbHits,
    onClick: handleOnClick,
    dispensaries: dispensaryData || [],
    showButtonPagination: Boolean(category),
    showMore: page?.isLastPage ? false : true,
    buttonText: buttonDispensariesText,
    bgColor: 'bg-white',
  };
};

export const getSearchArticlesData = (
  hits: any,
  results: any,
  page: any,
  category: any,
  query: any,
  isTablet: boolean,
  tasticPlacementNumber: number,
) => {
  const remainingCards = page?.nbHits - hits?.length;
  const data: any =
    (hits &&
      hits?.map((item) => {
        const listItemImage = isTablet ? item?.image : item?.mobile_image ? item?.mobile_image : item?.image;
        return {
          listItemImage,
          listItemTitle: item?.name || '',
          listItemDescription: item?.description || '',
          publishDate: item?.published_date || '',
          ...item,
        };
      })) ||
    [];
  const buttonArticlesText = category
    ? remainingCards > results?.hitsPerPage
      ? `Show more (${results?.hitsPerPage})`
      : `Show more (${remainingCards})`
    : 'See all articles results';
  const cardListData = {
    card_list_name: 'Article List- Search',
    card_list_title: 'Articles & Resources',
    card_list_type: '',
    card_list_facet_name: '',
    card_list_facet_value: '',
    card_list_url: '',
    link_name: buttonArticlesText,
    placement: tasticPlacementNumber || 1,
    link_type: 'hyperlink',
  };
  const handleOnClick = () => {
    handleSegmentEvent(cardListData);
    if (category) {
      page.refine(page.currentRefinement + 1);
    } else {
      Router.push(`/search?query=${encodeURIComponent(query)}&category=articles`);
    }
  };
  return {
    articles: data || [],
    showTitlePagination: true,
    bgColor: 'bg-white',
    sectionTitle: 'Articles & Resources',
    buttonText: buttonArticlesText || '',
    onClick: () => handleOnClick(),
    showButtonPagination: Boolean(category),
    showMore: page?.isLastPage ? false : true,
    current: hits?.length,
    max: results?.nbHits,
  };
};
