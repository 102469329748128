import Image from 'next/image';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import { Typography } from 'components/atoms/typography';
import {
  DispensaryCardContainer,
  ImageContainer,
  InfoContainer,
  InfoWrapper,
} from 'components/molecules/cards/dispensary-card/styles';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { IDispensaryCards } from 'helpers/types/common.type';
import { tablet } from 'helpers/utils/screensizes';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import LocationIcon from 'public/icons/figma/location.svg';
import PhoneIcon from 'public/icons/figma/phone.svg';
import TimeIcon from 'public/icons/figma/time.svg';

interface DispensaryCardProps {
  dispensaryObject: IDispensaryCards;
  host?: string;
  bottomStyling?: boolean;
  topPadding?: boolean;
}

export const DispensaryCard = ({ dispensaryObject, host, topPadding, bottomStyling }: DispensaryCardProps) => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const today = days[new Date().getDay()];
  const timing = dispensaryObject?.timing?.find((item) => {
    return item?.day?.includes(today);
  });
  const timingText = `${today} ${timing?.from} - ${timing?.to}`;
  const [isTablet] = useMediaQuery(tablet);

  const selectedImage = isTablet ? dispensaryObject?.image : dispensaryObject?.mobile_image;
  return (
    <DispensaryCardContainer
      $bottomStyling={bottomStyling ? 'border-b-solid border-b-[1px] bottom-b-grey-300' : '!pb-0'}
      $topPadding={topPadding ? '' : '!pt-0'}
    >
      {isTablet && (
        <ImageContainer>
          <Image
            loader={(options) => options.src}
            src={selectedImage?.url || ''}
            alt={selectedImage?.title || ''}
            objectFit="contain"
            width={selectedImage?.width}
            height={selectedImage?.height}
            className="dispensaryImage"
            priority
          />
        </ImageContainer>
      )}
      <InfoContainer $topPadding={topPadding ? '' : '!pt-0'}>
        <Typography variant="h4" as={'h3'}>
          {dispensaryObject?.title}
        </Typography>
        {!isTablet && (
          <ImageContainer>
            <Image
              loader={(options) => options.src}
              src={selectedImage?.url || ''}
              alt={selectedImage?.title || ''}
              objectFit="contain"
              width={selectedImage?.width}
              height={selectedImage?.height}
              priority
            />
          </ImageContainer>
        )}
        <InfoWrapper>
          <Typography variant="body-small" className="timingText mb-4 flex !text-grey-400 md:mb-3">
            <TimeIcon className="mr-2" />
            {timingText}
          </Typography>
          <Typography variant="body-small" className="contactText mb-4 flex !text-grey-400 md:mb-3">
            <PhoneIcon className="mr-2" />
            {dispensaryObject?.phoneNumber}
          </Typography>

          {dispensaryObject?.address?.text && (
            <Typography variant="body-small" className="addressText flex !text-grey-400">
              <LocationIcon className="mr-2" />
              {dispensaryObject?.address.text}
            </Typography>
          )}
        </InfoWrapper>
        <IconWithTextButton
          as="a"
          href={`${host}${dispensaryObject?.slug}`}
          button-type="tertiary"
          className="viewDispensaryLink !p-0"
          color="green"
          icon={<ChevronRightIcon />}
          iconPosition="after"
        >
          View dispensary
        </IconWithTextButton>
      </InfoContainer>
    </DispensaryCardContainer>
  );
};
