import React from 'react';
import Image from 'next/image';
import { BLOCKS } from '@contentful/rich-text-types';
import { useDispatch } from 'react-redux';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import { Typography } from 'components/atoms/typography';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { IContentfulImage, ICtaBtn, IRichTextData } from 'helpers/types/common.type';
import { tablet } from 'helpers/utils/screensizes';
import SearchIcon from 'public/icons/figma/search-small.svg';
import { updateSearch } from 'redux/reducers/search';
import {
  ButtonImageContainer,
  Buttonwrapper,
  Descriptionwrapper,
  NoResultContainer,
  NoResultTitleContainer,
  Titlewrapper,
} from './styles';

interface InoSearchResultProps {
  title: string;
  searchItem: string;
  description: IRichTextData;
  image: IContentfulImage;
  searchCta: {
    items: ICtaBtn[];
  };
}

const NoSearchResult = ({ title, description, image, searchCta, searchItem }: InoSearchResultProps) => {
  const [isTablet] = useMediaQuery(tablet);
  const dispatch = useDispatch();
  const handleSearchButtonClick = () => {
    dispatch(updateSearch({ searchBar: { isOpen: true } }));
  };

  return (
    <NoResultContainer>
      <NoResultTitleContainer>
        <Titlewrapper>
          <Typography variant="h2" as="p" className=" !text-grey-900">
            {title}
            <Typography variant="h2" as="p" className="ml-2 inline">
              {searchItem}
            </Typography>
          </Typography>
        </Titlewrapper>
        <Descriptionwrapper>
          <ContentfulRichText
            data={description}
            renderNodeOptions={{
              [BLOCKS.PARAGRAPH]: (node, children) => (
                <Typography variant="body-large" as="p" className=" !text-grey-400">
                  {children}
                </Typography>
              ),
            }}
          />
        </Descriptionwrapper>
      </NoResultTitleContainer>
      <ButtonImageContainer>
        <Buttonwrapper>
          <IconWithTextButton
            icon={<SearchIcon className="text-white" />}
            iconPosition="after"
            color="green"
            button-type="primary"
            onClick={handleSearchButtonClick}
          >
            {searchCta?.items?.[0]?.title}
          </IconWithTextButton>
        </Buttonwrapper>
        <div className="ml-6 pl-4 lg:col-span-2 lg:col-start-11">
          <Image
            src={image?.url || ''}
            alt={image?.title || ''}
            width={isTablet ? image?.width : 97}
            height={isTablet ? image?.height : 73}
            loader={(item) => item.src}
          />
        </div>
      </ButtonImageContainer>
    </NoResultContainer>
  );
};

export default NoSearchResult;
