'use client';

import { AutoSizer, CellMeasurer, CellMeasurerCache, List, WindowScroller } from 'react-virtualized';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import ArticleCardHorizontal from 'components/molecules/cards/article-card-horizontal';
import { IArticleCard } from 'helpers/types';
import { ArticleCardsDescription, ArticleCardsTitle, ArticleCardsWrapper, ShowMoreButton } from '../styles';

interface IAtricleCardsData {
  articles: IArticleCard[];
  bgColor: string;
  sectionTitle: string;
  showTitlePagination?: boolean;
  sectionDescription?: string;
  current?: number;
  max?: number;
  buttonText: string;
  showButtonPagination?: boolean;
  showMore?: boolean;
}

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 256,
});

const ArticleCardsVariantB = ({
  articles,
  bgColor,
  sectionTitle,
  sectionDescription,
  showTitlePagination = false,
  current = 3,
  max = 3,
  showButtonPagination = false,
  showMore = false,
  buttonText = '',
  ...buttonProps
}: IAtricleCardsData) => {
  const filteredArticles = articles.filter((article) => Boolean(article));
  const totalArticles = filteredArticles.length;

  const renderItem = ({ index, key, style, parent }) => {
    const item = filteredArticles[index];
    return (
      <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
        {({ registerChild, measure }) => (
          <div style={style} className="row" ref={registerChild} onLoad={measure}>
            <ArticleCardHorizontal {...item} bgColor={bgColor} ctaText="Read the article" />
            {index !== totalArticles - 1 ? <hr className="my-[24px] h-[1px] text-grey-300" /> : null}
          </div>
        )}
      </CellMeasurer>
    );
  };

  return (
    totalArticles > 0 && (
      <ArticleCardsWrapper $bgColor={bgColor}>
        <ArticleCardsTitle>
          <Typography variant="h3" className="flex !text-grey-500" data-testid={`article-cards-title`}>
            {sectionTitle}
            {showTitlePagination && (
              <Typography variant="body-large" className="pl-2 !text-grey-400">
                <sup className="top-0">{`(${current} of ${max})`}</sup>
              </Typography>
            )}
          </Typography>
          {sectionDescription && (
            <Typography variant="body-large" className={ArticleCardsDescription}>
              {sectionDescription}
            </Typography>
          )}
        </ArticleCardsTitle>
        <WindowScroller>
          {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
            <div className="list" data-testid={`article-cards`}>
              <AutoSizer disableHeight onResize={() => cache.clearAll()}>
                {({ width }) => (
                  <div ref={(e) => registerChild(e)}>
                    <List
                      width={width}
                      height={height}
                      autoHeight
                      isScrolling={isScrolling}
                      onScroll={onChildScroll}
                      scrollTop={scrollTop}
                      rowHeight={cache.rowHeight}
                      rowRenderer={renderItem}
                      rowCount={filteredArticles.length}
                      autoContainerWidth
                      overscanRowCount={3}
                      data-testid={'article-cards'}
                    />
                  </div>
                )}
              </AutoSizer>
            </div>
          )}
        </WindowScroller>
        {showMore && (
          <ShowMoreButton>
            {showButtonPagination && (
              <Typography variant="body-small" className="pagination mb-4 mr-0 !text-grey-400 md:my-3.5 md:mr-6">
                {`Showing ${current} of ${max}`}
              </Typography>
            )}
            {buttonText && (
              <CommonButton
                button-type="secondary"
                color="green"
                data-testid="related-articles-button"
                size="sm"
                {...buttonProps}
              >
                {buttonText}
              </CommonButton>
            )}
          </ShowMoreButton>
        )}
      </ArticleCardsWrapper>
    )
  );
};

export default ArticleCardsVariantB;
