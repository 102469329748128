import tw from 'tailwind-styled-components';
interface IArticleStyleProps {
  $bgColor?: string;
}
export const DispensaryCardsContainer = tw.div<IArticleStyleProps>`
    bg-grey-100
    md:py-[120px]
    py-10
    md:px-10
    px-5
    ${(p) => (p.$bgColor ? p.$bgColor : 'bg-grey-100')}
`;

export const HeadingContainer = tw.div`
    md:mb-[64px]
    mb-8
    flex
`;
export const ButtonContainer = tw.div`
    w-full
    justify-center
    md:flex
    block
    text-center
    md:mt-10
    mt-8
`;
