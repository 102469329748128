'use client';

import { memo, useEffect, useState } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import { Configure, InstantSearch } from 'react-instantsearch';
import Loading from 'components/organisms/global/loader';
import ProductCards from 'components/organisms/listings/product-cards';
import NoSearchResultSection from 'components/organisms/search/no-search-result/no-search-result-section';
import { gtiSearchClient } from 'helpers/algolia/clients';
import { getGTIIndexName } from 'helpers/algolia/get-index-name';
import useDispensary from 'helpers/hooks/use-dispensary';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { getProductPaginationApi } from 'helpers/product-pagination-api';
import { tablet } from 'helpers/utils/screensizes';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { updateSearch } from 'redux/reducers/search';
import ArticlesHits from './article-hits';
import DispensaryHits from './dispensary-hits';

interface TProps {
  data: Record<string, any>;
  pageFolder: Record<string, any>;
}

const SearchCategoryCardsTastic = ({ pageFolder, data }: TProps) => {
  const host = pageFolder.host;
  const isOH = host?.includes('oh');
  const { currentDispensary } = useDispensary();
  const dispatch = useAppDispatch();
  const search: any = useAppSelector((state) => state?.search?.data || {});

  const variant = data.variant;
  const router = useRouter();
  const params = useSearchParams();

  const filters = isOH ? 'domain:Ohio' : 'domain:Main';

  const [productData, setProductData] = useState({
    algolia: null,
    algolia_total: null,
    algolia_total_page: null,
  });

  const [isTablet] = useMediaQuery(tablet);
  const category = params.get('category');
  const query = params.get('query');

  const hitsPerPage = category ? (category === 'articles' ? (isTablet ? 6 : 5) : 6) : 3;

  const handleProduct = async () => {
    updateSearch({
      isProductsLoading: true,
    });
    await getProductPaginationApi(`?query=${query}&store_id=${currentDispensary?.dispensaries?.storeId}`)
      .then((data) => {
        dispatch(
          updateSearch({
            productsData: data?.algolia_total || 0,
          }),
        );
        if (data) {
          setProductData(data);
        }
      })
      .catch((error) => console.log('product card error-->', error))
      .finally(() =>
        updateSearch({
          isProductsLoading: false,
        }),
      );
  };

  useEffect(() => {
    if (variant === 'products') {
      handleProduct();
    }
  }, [variant, query]);

  if (
    (category &&
      ((category === 'articles' && variant !== 'site-pages') ||
        (category === 'dispensaries' && variant !== 'dispensary'))) ||
    (category === 'products' && variant !== 'products')
  ) {
    return <></>;
  }

  return (
    <>
      {(search?.isProductsLoading || search?.isArticlesLoading || search?.isDispensariesLoading) && <Loading />}
      {variant === 'products' && (category === null || category === 'products') && productData?.algolia && (
        <ProductCards
          initialData={productData?.algolia || []}
          title={
            search?.productsData > 0 ? (category ? `SHOWING ${productData?.algolia_total || 0} ` : 'Products') : ''
          }
          showTitlePagination={search?.productsData > 0 ? Boolean(!category) : false}
          totalProductCard={productData?.algolia_total || 0}
          totalPages={productData?.algolia_total_page || 0}
          buttonText="See all products results"
          showFooterMoreButton
          showFooterPagination={Boolean(category)}
          handleButtonClick={
            !category
              ? () => router.push(`/search?query=${encodeURIComponent(String(query))}&category=products`)
              : () => {}
          }
          noResultComponent={
            search?.productsData <= 0 && search?.articlesData <= 0 && search?.dispensariesData <= 0 ? (
              <NoSearchResultSection />
            ) : (
              <></>
            )
          }
        />
      )}
      {variant === 'site-pages' && (category === null || category === 'articles') && (
        <InstantSearch indexName={getGTIIndexName(variant)} searchClient={gtiSearchClient}>
          <Configure hitsPerPage={hitsPerPage} analytics={false} query={String(query) || ''} filters={filters} />
          <ArticlesHits
            category={category as string}
            query={query as string}
            tasticPlacementNumber={data.tasticPlacementNumber}
            isTablet={isTablet}
          />
        </InstantSearch>
      )}
      {variant === 'dispensary' && (category === null || category === 'dispensaries') && (
        <InstantSearch indexName={getGTIIndexName(variant)} searchClient={gtiSearchClient}>
          <Configure hitsPerPage={hitsPerPage} analytics={false} query={String(query) || ''} filters={filters} />
          <DispensaryHits
            category={category as string}
            query={query as string}
            tasticPlacementNumber={data.tasticPlacementNumber}
            host={host}
          />
        </InstantSearch>
      )}
    </>
  );
};

export default memo(SearchCategoryCardsTastic);
