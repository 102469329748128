import tw from 'tailwind-styled-components';

export const NoResultContainer = tw.div`
    w-auto
    grid
    grid-rows-2
    gap-10
    px-5 
    py-10 
    md:px-[60px] 
    md:py-20 
`;

export const NoResultTitleContainer = tw.div`
    flex 
    flex-col 
    gap-6 
    lg:grid 
    lg:grid-cols-12
`;

export const Titlewrapper = tw.div`
    lg:col-span-6 
    lg:row-start-1
`;

export const Descriptionwrapper = tw.div`
    g:row-star-2 
    lg:col-span-3 
    lg:col-start-1
`;

export const ButtonImageContainer = tw.div`
    row-span-1 
    flex h-12 
    gap-10 
    lg:grid 
    lg:grid-cols-12 
    lg:gap-2
`;

export const Buttonwrapper = tw.div`
    lg:col-span-2
`;

export const Imagewrapper = tw.div`
    ml-6 
    pl-4 
    lg:col-span-2 
    lg:col-start-11
`;
