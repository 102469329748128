import { fetchApiHub } from 'frontastic';
const overrideStudioCFUrl = process.env.NEXT_PUBLIC_USE_CF_URL === 'true' ? 'YES' : 'NO';
const overrideUrl = process.env.NEXT_PUBLIC_CF_URL;

export const fetchApiHubWithCfOverideContext = async (endpointPath, query = '', init = {}, payload = {}) => {
  const overideString =
    overrideStudioCFUrl === 'YES'
      ? `${query ? '&' : '?'}gti-override-studio-cf=${overrideStudioCFUrl}&gti-override-cf-url=${overrideUrl}`
      : '';
  const updatedQuery = `${query ?? ''}${overideString}`;

  const newEndpoint = `${endpointPath}${updatedQuery}`;

  return fetchApiHub(newEndpoint, init, payload);
};
