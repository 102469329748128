import { differenceInDays } from 'date-fns';
import Label from 'components/atoms/labels/text-and-icon';

const PublishNewLabel = ({ date, daysToExpire = 15 }: { date: string; daysToExpire?: number }) => {
  const days = differenceInDays(new Date(), date);

  return days <= daysToExpire && days >= -14 ? (
    <Label data-testid="newlabel-test" variant="kief">
      New
    </Label>
  ) : (
    <></>
  );
};

export default PublishNewLabel;
