'use client';

import Image from 'next/image';
import Link from 'next/link';
import { formatDate } from 'date-fns';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import PublishNewLabel from 'components/atoms/labels/publish-new';
import Label from 'components/atoms/labels/text-and-icon';
import { Typography } from 'components/atoms/typography';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { IArticleCard } from 'helpers/types';
import { parseCategoryUrl } from 'helpers/utils/category-url-parse';
import { getLinkTarget } from 'helpers/utils/common';
import { tablet } from 'helpers/utils/screensizes';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import { useAppSelector } from 'redux/hooks';
import {
  ArticleCardBodyContainer,
  ArticleCardLabels,
  ArticleCardTextContainer,
  ArticleCardWrapper,
  ArticleCardsImageContainer,
} from './styles';

const ArticleCardHorizontal = ({
  publishDate,
  category = 'Recipe',
  listItemTitle,
  listItemImages,
  listItemImage,
  ctaText,
  listItemDescription,
  slug,
  bgColor,
}: IArticleCard) => {
  const [isTablet] = useMediaQuery(tablet);
  const host = useAppSelector((state) => state.hostReducer.host);
  const newSlug = host + slug;
  const urlCategory = category ? parseCategoryUrl(category) : '';
  const image = listItemImages ? listItemImages : listItemImage;
  const articleCardTime = publishDate && formatDate(new Date(publishDate), 'MM.dd.yyyy');

  return (
    <ArticleCardWrapper $bgColor={bgColor}>
      <ArticleCardsImageContainer>
        <Image
          className="h-full  w-full rounded-md"
          data-testid="card-image"
          alt={
            isTablet ? image?.desktopImage?.title || '' : image?.mobileImage?.title || image?.desktopImage?.title || ''
          }
          loader={(options) => options?.src}
          src={isTablet ? image?.desktopImage?.url || '' : image?.mobileImage?.url || image?.desktopImage?.url || ''}
          unoptimized
          layout="fill"
        />
      </ArticleCardsImageContainer>
      <ArticleCardBodyContainer>
        <ArticleCardLabels data-testid="card-labels">
          <PublishNewLabel date={publishDate} />
          {category && (
            <Link
              href={`${host}/blog?filter-category=${urlCategory}`}
              target={getLinkTarget(`${host}/blog?filter-category=${urlCategory}`)}
            >
              <Label variant="grey-200" className="cursor-pointer">
                {category}
              </Label>
            </Link>
          )}
          {category !== 'Resources' && articleCardTime && (
            <Label variant="white" className="bg-white">
              {articleCardTime}
            </Label>
          )}
        </ArticleCardLabels>
        <ArticleCardTextContainer>
          <div className="flex flex-col gap-2 md:gap-2.5 2xl:gap-4">
            <Typography data-testid="card-title" variant="h4" as={'a'} target={getLinkTarget(newSlug)} href={newSlug}>
              {listItemTitle}
            </Typography>
            <Typography
              data-testid="card-description"
              variant="body-large"
              className="min-w-[250px] max-w-[675px]  !text-grey-400 [&_div_p]:pb-0"
            >
              {typeof listItemDescription === 'string' ? (
                listItemDescription
              ) : (
                <ContentfulRichText data={listItemDescription} />
              )}
            </Typography>
          </div>
          <IconWithTextButton
            color="green"
            as={'a'}
            target={getLinkTarget(newSlug)}
            href={newSlug}
            data-testid="card-button"
            button-type="tertiary"
            icon={<ChevronRightIcon />}
            iconPosition="after"
            className="!p-0"
          >
            {category === 'Resources' ? 'Go to Resource' : ctaText}
          </IconWithTextButton>
        </ArticleCardTextContainer>
      </ArticleCardBodyContainer>
    </ArticleCardWrapper>
  );
};

export default ArticleCardHorizontal;
