import tw from 'tailwind-styled-components';
interface DispensaryCardContainerProps {
  $bottomStyling: string;
  $topPadding: string;
}
export const DispensaryCardContainer = tw.div<DispensaryCardContainerProps>`
    flex
    ${(p) => p.$bottomStyling}
    ${(p) => p.$topPadding}
    md:py-6
    py-10
`;

export const ImageContainer = tw.div`
    my-5
    md:my-0
    [&_img]:max-w-[520px]
    [&_img]:max-h-[292px]
`;
interface InfoContainerProps {
  $topPadding: string;
}
export const InfoContainer = tw.div<InfoContainerProps>`
    ${(p) => p.$topPadding}

    md:py-11
    py-5
    md:ml-[90px]
    ml-0
`;

export const InfoWrapper = tw.div`
    md:py-6
    pt-0
    pb-5
`;

export const IconStyle = `
    ml-2
    mt-1
`;
