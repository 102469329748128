import { useEffect } from 'react';
import { useInfiniteHits, useInstantSearch, usePagination, useSortBy } from 'react-instantsearch';
import ArticleCardsVariantB from 'components/organisms/media/article-cards/variant-b-article-cards';
import { getGTIIndexName } from 'helpers/algolia/get-index-name';
import { getSearchArticlesData } from 'helpers/utils/get-search-category-data';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { updateSearch } from 'redux/reducers/search';

type Props = {
  category: string | string[];
  query: string | string[];
  tasticPlacementNumber: number;
  host?: string;
  isTablet?: boolean;
};

const ArticlesHits = ({ category, query, isTablet, tasticPlacementNumber }: Props) => {
  const { hits, results } = useInfiniteHits();
  const { status } = useInstantSearch();
  const dispatch = useAppDispatch();
  const page = usePagination();
  const { options, refine } = useSortBy({
    items: [
      { label: 'Oldest', value: getGTIIndexName('site-pages') },
      { label: 'Newest', value: getGTIIndexName('site-pages-by-pub-date-desc') },
    ],
  });
  const search: any = useAppSelector((state) => state?.search?.data || {});
  const propsCard: any = getSearchArticlesData(
    hits,
    results,
    page,
    category,
    query,
    isTablet as boolean,
    tasticPlacementNumber,
  );

  useEffect(() => {
    dispatch(
      updateSearch({
        articlesData: page?.nbHits,
        isArticlesLoading: status === 'loading' || status === 'stalled' || false,
      }),
    );
  }, [dispatch, page?.nbHits, status]);

  useEffect(() => {
    const selectedItem = options?.find((item) => item?.label === search?.sortValue);
    refine(selectedItem?.value as string);
  }, [search?.sortValue]);

  return <ArticleCardsVariantB {...propsCard} />;
};

export default ArticlesHits;
