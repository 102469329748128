'use client';

import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import CarouselProduct from 'components/organisms/carousel/carousel-product';
import Loading from 'components/organisms/global/loader';
import useDispensary from 'helpers/hooks/use-dispensary';
import { getNoSearchApi } from 'helpers/search-api';
import { getPathMenuFiltersLink } from 'helpers/utils/common';
import NoSearchResult from './no-search-result';

const NoSearchResultSection = () => {
  const [data, setData] = useState(null);
  const { currentDispensary } = useDispensary();
  const [loading, setLoading] = useState(false);

  const params = useSearchParams();

  const handleNoSearchResult = async () => {
    setLoading(true);
    await getNoSearchApi(`?store_id=${currentDispensary?.dispensaries?.storeId}`)
      .then((res) => {
        if (res?.data?.page?.items?.[0]) {
          setData(res?.data?.page?.items?.[0]);
        }
      })
      .catch((error) => console.log('error----->', error))
      .finally(() => setLoading(false));
  };

  const _renderProductList = (data) => {
    if (data?.cms?.__typename === 'SectionProductCarousel') {
      const { cms, algolia } = data || {};
      const { algoliaId, algoliaValue } = cms.facetTypeCollection.items?.[0];
      return (
        algolia?.length > 0 && (
          <CarouselProduct
            data={algolia || []}
            type={cms?.carouselVariant}
            title={cms?.heading}
            kind={algolia.variants_details}
            description={cms?.description}
            buttonLink={getPathMenuFiltersLink(cms?.cta?.longUrl, algoliaId, algoliaValue) || '/'}
            buttonTitle={cms?.cta?.title}
            order={1}
          />
        )
      );
    }
  };

  useEffect(() => {
    handleNoSearchResult();
  }, []);

  return (
    <>
      {loading && <Loading />}
      {/* @ts-ignore */}
      {data?.heroSection && (
        <NoSearchResult
          //@ts-ignore
          {...data?.heroSection}
          searchItem={`“${params}”`}
          //@ts-ignore
          searchCta={data?.heroSection?.ctaCollection}
        />
      )}
      {/* @ts-ignore */}
      {_renderProductList(data?.component?.[0])}
    </>
  );
};

export default NoSearchResultSection;
