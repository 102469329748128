import { fetchApiHubWithCfOverideContext } from 'helpers/utils/fetch-api-hub-with-cf-overide-context';

export const getNoSearchApi = async (query) => {
  try {
    const res = await fetchApiHubWithCfOverideContext(`/action/search/no-result`, query, {
      method: 'POST',
    });
    return res?.dataSourcePayload;
  } catch (err) {
    console.log('error', err);
    return { error: 400, data: {} };
  }
};
