import tw from 'tailwind-styled-components';

interface IArticleCardProps {
  $bgColor?: string;
}

export const ArticleCardWrapper = tw.div<IArticleCardProps>`
  flex 
  flex-row 
  2xl:gap-[90px]
  md:gap-[60px]
  gap-5
  md:items-center
  item-start
  ${(p) => (p.$bgColor ? p.$bgColor : 'bg-grey-100')}
`;

export const ArticleCardBodyContainer = tw.div`
  2xl:w-[976px] 
  md:w-[804px]
  2xl:min-h-[200px]
  md:min-h-[164px]
  min-h-[172px]
  h-full
  flex
  flex-col
  2xl:gap-5
  md:gap-2.5
  gap-4
`;

export const ArticleCardLabels = tw.div`
  flex 
  flex-row 
  md:gap-2.5
  gap-2
`;

export const ArticleCardsImageContainer = tw.div`
  relative 
  2xl:max-w-[520px]
  2xl:h-[292px]
  md:max-w-[336px]
  md:h-[211px]
  h-[80px] 
  max-w-[80px]
  w-full
  overflow-hidden 
`;

export const ArticleCardTextContainer = tw.div`
  flex
  flex-col
  2xl:gap-4
  md:gap-2.5
  gap-4
`;
