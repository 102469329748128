import { AutoSizer, CellMeasurer, CellMeasurerCache, List, WindowScroller } from 'react-virtualized';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import { DispensaryCard } from 'components/molecules/cards/dispensary-card';
import {
  ButtonContainer,
  DispensaryCardsContainer,
  HeadingContainer,
} from 'components/organisms/listings/dispensary-cards/styles';
import { IDispensaryCards } from 'helpers/types/common.type';

interface DispensaryCardsProps {
  dispensaries: Array<IDispensaryCards>;
  host: string;
  current?: number;
  max?: number;
  onClick?: () => void;
  buttonText?: string;
  isDisabled?: boolean;
  showMore?: boolean;
  title?: string;
  showButtonPagination?: boolean;
  bgColor?: string;
}
const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 100,
});

export const DispensaryCards = ({
  dispensaries,
  host,
  current,
  max,
  onClick,
  buttonText,
  isDisabled = false,
  showMore = false,
  title = '',
  showButtonPagination = false,
  bgColor = '',
}: DispensaryCardsProps) => {
  const handlePaginationClick = () => {
    if (onClick) {
      onClick();
    }
  };
  const renderItem = ({ index, key, style, parent }) => {
    const dispensary = dispensaries[index];
    return (
      <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
        {({ registerChild, measure }) => (
          <div style={style} className="row" ref={registerChild} onLoad={measure}>
            <DispensaryCard
              key={index}
              dispensaryObject={dispensary}
              host={host}
              topPadding={index !== 0}
              bottomStyling={index !== dispensaries?.length - 1}
            />
          </div>
        )}
      </CellMeasurer>
    );
  };

  return (
    dispensaries?.length > 0 && (
      <DispensaryCardsContainer $bgColor={bgColor}>
        <HeadingContainer>
          {title && (
            <Typography variant="h3" as={'h2'} className={'!text-grey-500'}>
              {title}
            </Typography>
          )}
          {dispensaries?.length > 2 && (
            <Typography
              variant="body-small"
              className="paginationHeading ml-2 !text-grey-400"
            >{`(${current} of ${max})`}</Typography>
          )}
        </HeadingContainer>
        <div className="dispensaryCardWrapper">
          <WindowScroller>
            {({ height, isScrolling, onChildScroll, registerChild, scrollTop }) => (
              <AutoSizer disableHeight onResize={() => cache.clearAll()}>
                {({ width }) => (
                  <div ref={(e) => registerChild(e)}>
                    <List
                      width={width}
                      height={height}
                      autoHeight
                      isScrolling={isScrolling}
                      onScroll={onChildScroll}
                      scrollTop={scrollTop}
                      rowHeight={cache.rowHeight}
                      rowRenderer={renderItem}
                      rowCount={dispensaries?.length}
                      autoContainerWidth
                      overscanRowCount={3}
                    />
                  </div>
                )}
              </AutoSizer>
            )}
          </WindowScroller>
        </div>
        {showMore && (
          <ButtonContainer>
            {showButtonPagination && (
              <Typography
                variant="body-small"
                className="pagination mb-4 mr-0 !text-grey-400 md:my-3.5 md:mr-6"
              >{`Showing ${current} of ${max}`}</Typography>
            )}
            <CommonButton
              disabled={isDisabled}
              onClick={() => handlePaginationClick()}
              button-type="secondary"
              color="green"
              size="sm"
              className="showMoreButton"
            >
              {buttonText}
            </CommonButton>
          </ButtonContainer>
        )}
      </DispensaryCardsContainer>
    )
  );
};
